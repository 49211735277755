import React from 'react';
import './css/main.css';
import noPaySVG from './noPaySVG.svg';


export const PayRetiredContainer = () => {
  return (
    <div>
      <head>
        <link
          href="https://fonts.googleapis.com/css?family=Apercu+Pro&display=swap"
          rel="stylesheet"
        />
        <link href="./css/main.css" rel="stylesheet" />
        <title>Document</title>
      </head>
      <body>
        <div className="MainDiv">
            <span className="BodyText">
              <br />
              <br />
              Helix Pay has been replaced with a PowerBi dashboard. To view your pay details, refer to the One Stop Pay Shop:
              <a href= "https://rebrand.ly/OneStopPayShop"> https://rebrand.ly/OneStopPayShop </a>
              <br />
              <br />
              For questions please refer to your leader.
            </span>
            <span className="PayHeader">Helix Pay has been retired...</span> 
        </div>
        <img src={noPaySVG} className="image" alt="helix logo" /> 
      </body>
    </div>
  );
};

export default PayRetiredContainer;
