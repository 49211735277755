/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mutateSettings = /* GraphQL */ `
  mutation MutateSettings($employeeId: String!, $settings: AWSJSON) {
    mutateSettings(employeeId: $employeeId, settings: $settings) {
      employeeId
      settings
    }
  }
`;
