import { AdminDataViewReduxState } from '@helix/admin-data-view-ui';

export const DEFAULT_FUNCTION = () => {};

export const CONTENT_TYPE = 'Content-Type';

export class LocalStorageItem {
  constructor(value = false, expiry = 0, ttl = 0) {
    this.value = value;
    this.expiry = expiry;
    this.ttl = ttl;
  }
}

export class ReduxLocation {
  constructor(pathname = '', search = '') {
    this.pathname = pathname;
    this.search = search;
  }
}

export class ReduxStateWithoutPrivateData {
  constructor(core = {}, costCenters = [], leaderView = {}) {
    this.core = core;
    this.costCenters = costCenters;
    this.leaderView = leaderView;
  }
}

export class HelixUser {
  constructor(
    givenName = '',
    familyName = '',
    email = '',
    userId = '',
    employeeId = '',
    teamMembers = [],
    roles = []
  ) {
    this.givenName = givenName;
    this.familyName = familyName;
    this.email = email;
    this.userId = userId;
    this.employeeId = employeeId;
    this.teamMembers = teamMembers;
    this.roles = roles;
  }
}

export class Setting {
  constructor(name = '', value = '') {
    this.name = name;
    this.value = value;
  }
}

export class SettingsAndHandlers {
  constructor(
    settings = [],
    changeHandler = DEFAULT_FUNCTION,
    iconClickHandler = DEFAULT_FUNCTION,
    blurHandler = DEFAULT_FUNCTION,
    showSettings = false
  ) {
    this.settings = settings;
    this.changeHandler = changeHandler;
    this.iconClickHandler = iconClickHandler;
    this.blurHandler = blurHandler;
    this.showSettings = showSettings;
  }
}

export class HelixAppNameAndBuildVersion {
  constructor(appName = '', buildVersion = '') {
    this.appName = appName;
    this.buildVersion = buildVersion;
  }
}

export class Body {
  constructor(message = '') {
    this.message = message;
  }
}

export class PostBody {
  constructor(body = {}, headers = {}) {
    this.body = body;
    this.headers = headers;
  }
}

export class HelixAPICall {
  constructor(apiName = '', apiPath = '', restMethod = '', postBody = {}) {
    this.apiName = apiName;
    this.apiPath = apiPath;
    this.restMethod = restMethod;
    this.postBody = postBody;
  }
}

export class HelixDataObservable {
  constructor(emitData = DEFAULT_FUNCTION, getData = DEFAULT_FUNCTION) {
    this.emitData = emitData;
    this.getData = getData;
  }
}

export class Headers {
  constructor(contentType = '') {
    this[CONTENT_TYPE] = contentType;
  }
}

export class LogItem {
  constructor(body = {}, timestamp = '') {
    this.body = body;
    this.timestamp = timestamp;
  }
}

export class ToggleStyle {
  constructor(paddingLeft = '') {
    this.paddingLeft = paddingLeft;
  }
}

export class SignInPayload {
  constructor(helixUser = {}, authenticated = false) {
    this.helixUser = helixUser;
    this.authenticated = authenticated;
  }
}

export class PayViewData {
  constructor(employeePayPeriods = [], teamMemberPayData = []) {
    this.employeePayPeriods = employeePayPeriods;
    this.teamMemberPayData = teamMemberPayData;
  }
}

export class EmployeeData {
  constructor(settings = [], payViewData = new PayViewData()) {
    this.settings = settings;
    this.payViewData = payViewData;
  }
}

export class TeamMember {
  constructor(employeeId = '', name = '', costCenter = '', payGroup = '') {
    this.employeeId = employeeId;
    this.name = name;
    this.costCenter = costCenter;
    this.payGroup = payGroup;
  }
}

export class LeaderData {
  constructor(employeePayPeriods = [], teamMemberPayData = []) {
    this.employeePayPeriods = employeePayPeriods;
    this.teamMemberPayData = teamMemberPayData;
  }
}

export class Core {
  constructor(authenticated = false, helixUser = {}, showSettings = false) {
    this.authenticated = authenticated;
    this.helixUser = helixUser;
    this.showSettings = showSettings;
  }
}

export class ReduxState {
  constructor(
    core = new Core(),
    employeeData = new EmployeeData(),
    leaderView = new LeaderData(),
    adminDataView = new AdminDataViewReduxState()
  ) {
    this.core = core;
    this.employeeData = employeeData;
    this.leaderView = leaderView;
    this.adminDataView = adminDataView;
  }
}

export const reduxStateToAdminDataViewState = ({ adminDataView } = new ReduxState()) =>
  adminDataView;

export default Core;
